<template>
  <div class="wrapper flex fd-c ai-c">
    <textarea class="textarea" placeholder="欢迎给我们商城提供建议或发现问题，谢谢小主们的信任，么么哒~"></textarea>
    <div class="txt">最多输入120个字</div>
    <div class="uploader-box">
      <van-uploader v-model="pic" multiple :max-count="6" />
    </div>
    <div class="btn" @click="submit">提交反馈</div>
  </div>
</template>

<script>
// import { fileUpload } from "../../../api";
export default {
  components:{},
  props:{},
  data(){
    return {
      pic: []
    }
  },
  watch:{},
  computed:{},
  methods:{
    submit() {
      this.$toast.loading({
        message: '提交中...',
        forbidClick: true,
        duration: 0
      })
      let p1 = new Promise((resolve, reject) => {
        function up (files, Length, i) {
            if (Length>0) {
              fileUpload(files[i].file).then(res=>{
                i++
                Length--
                that.form.deed.push(res.data.data)
                up(files, Length, i)
              })
            } else {
              resolve()
            }
        }
        var Length = this.pic.length
        up(this.pic,Length,0)
      });
      Promise.all([p1]).then((values) => {
        //  this.form.deed = this.form.deed.length>0?this.form.deed.join(','):this.form.deed
        //  this.form.cardImage = this.form.cardImage.length>0?this.form.cardImage.join(','):this.form.cardImage
        //  individual(this.form).then(res=>{
        //    this.$router.replace({path:'/preInstalled/choice/success'})
        //  }).finally(()=>{
        //    this.$toast.clear()
        //  })
      }).catch(()=>{
        this.$toast.clear()
      });
    }
  },
  created(){},
  mounted(){}
}
</script>
<style scoped>
.wrapper{
  width: 100%;
  min-height: 100vh;
}
.wrapper .textarea{
  width: 6.94rem;
  padding: .2rem .15rem;
  box-sizing: border-box;
  margin-top: .3rem;
  background: #F5F5F5;
  border-radius: .14rem;
  font-size: .26rem;
  font-family: PingFang;
  font-weight: 500;
  color: #555;
  height: 3.26rem;
  border: 0;
  resize: none;
}
.txt{
  width: 100%;
  padding-right: 0.27rem;
  box-sizing: border-box;
  font-size: .24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #969696;
  margin-top: .2rem;
  text-align: right;
}
.uploader-box{
  width: 100%;
  padding: 0 .3rem;
  box-sizing: border-box;
}
.wrapper >>> .van-uploader__upload-icon {
  width: 100%;
  height: 100%;
  background-image: url(/img/yl.png);
  background-size: 100% 100%;
}
.wrapper >>> .van-uploader__upload-icon::before {
  content: "";
}
.btn{
  position: fixed;
  left: calc(50% - 3.45rem);
  bottom: .24rem;
  width: 6.90rem;
  height: .90rem;
  background: #F35E3A;
  border-radius: .45rem;
  font-size: .36rem;
  font-family: PingFang;
  font-weight: 500;
  color: #FFFFFF;
  line-height: .9rem;
  text-align: center;
}
</style>